
function InfoPanel(props) {
    return (

        <div className='w-11/12 m-auto '>

          <ul className='text-left w-fill md:w-1/2 m-auto leading-loose bg-white my-2 p-3 border border-black'>
            <li>
              <small className='text-left text-gray-600'>{props.date}</small> <br /> {props.news} <br /> <a className='text-sm block text-center bg-black text-white' href={props.link}>{props.linkText}</a>
            </li>
          </ul>




        </div>

    )
}

export default InfoPanel;