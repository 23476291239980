function News () {
    return (
        <div className="py-32 w-11/12 md:w-1/2 m-auto">
            <h1 className="text-xl font-bold py-5">フットゴルフW杯出場辞退のお知らせ</h1>

            <div className="text-left py-3">
                <p>いつも加藤順大への応援ありがとうございます。<br />
                アメリカ合衆国オーランド(フロリダ州)で開催予定のフットゴルフW杯の出場を、体調不良により辞退することとなりましたのでお知らせします。<br />
                出国前の体調不良発生の為、現在は日本にて休養に専念しております。<br />今後の状況などに関しましては追ってご報告させていただきます。</p>
            </div>

            <div className="text-left">
                <p>以下コメント</p>
                <p className="italic font-bold py-3">日本代表に選ばれてからたくさんの応援を肌で感じていました。とてもとても幸せを感じた分、申し訳なさがその何十倍にも感じます。身体の痛みより心がそれ以上に痛いです。本当に申し訳ないです。まずは少しでも早く回復し、皆様に元気な姿をお見せできるように努めます。
日頃から応援、サポートしていただいている皆様お一人お一人にご連絡できず、大変申し訳ございません。
体調が戻り次第ご連絡させていただきたく存じます。 </p>
                <p>なお、ご支援していただいた商品に関しましては返金対応も含め、こちらからご連絡させていただきます。</p>
            </div>

        </div>
    );
}

export default News;