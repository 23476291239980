import '../tailwind.css';


function Contact() {
  
  return (
    <div className='bg-gray-700 text-white'>
          <div className='contact-wrapper content-width text-white'>
            <h1 className='title '>Contact</h1>
      <div className='button-wrapper w-11/12 py-5 mx-auto text-xl'>

        <p className='text-xs'>取材等はこちらのメールアドレスまで <span className='inline-block'>お願い致します。</span></p>

        <button className='button text-sm'> <a className='block mailtoui' href="mailto:nobuhirokato1@gmail.com" >Mail:<span className='inline-block'>nobuhirokato1@gmail.com</span></a></button>
        <br />
        <button className='button text-sm'> <a className='block' href="https://instagram.com/nobuhiro1.footgolf" >Instagram</a></button>
        <br />
        <button className='button text-sm'> <a className='block' href="https://twitter.com/nobuhirokatogk1" >Twitter</a></button>

      </div>

    </div>

    </div>

  );
}

export default Contact;
