import '../tailwind.css';
import PartnersPanel from './Parts/PartnersPanel';


function Supporiting() {
  const partner = [
    {
      img:"takagi.jpg",
      link:"https://www.kameranotakagi.com/",
      alt:"カメラのタカギ"
    },
    {
      img:"akippa.jpg",
      link:"https://www.akippa.com/",
      alt:"akippa"
    },
    {
      img:"846.jpg",
      link:"https://846yajiro.com/",
      alt:"846"
    },
    {
      img:"seiwa.jpg",
      link:"https://www.seiwag.jp/",
      alt:"セイワ技研"
    },
    {
      img:"daigoro.jpg",
      link:"https://horumon-daigoro.net/",
      alt:"ほるもん大五郎"
    },
    {
      img:"wadachi.jpg",
      link:"https://www.instagram.com/menyawadachi/?hl=ja",
      alt:"麺屋轍"
    },
    {
      img:"takido.jpg",
      link:"https://www.instagram.com/takidofarm/?hl=ja",
      alt:"滝戸農園"
    },
    {
      img:"cachitto.jpg",
      link:"https://cachitto-page.com/",
      alt:"カチッとページ"
    },
    {
      img:"stage.jpg",
      link:"https://stage-kyoto.com/",
      alt:"ステージ"
    },
    {
      img:"codalunga.jpg",
      link:"https://coda-lunga.com/",
      alt:"CODALUNGA"
    },

    {
      img:"whiteclan.jpg",
      link:"#",
      alt:"whiteclan"
    },




  ]

  
  return (
    <div className='bg-gray-100'>
       <h2 className='text-center font-bold text-2xl py-5'>Partners</h2>

       <div className='w-11/12 md:w-2/3 m-auto columns-2 md:columns-3'>
       {partner && partner.map ((content)=> (
            <PartnersPanel
                 link={content.link}
                 img={content.img}
                 alt={content.alt}
            
            
            />

            ))}
       </div>
    </div>
  );
}

export default Supporiting;
