import Home from './Pages/Home';
import Football from './Pages/Football';
import Footgolf from './Pages/Footgolf';
import Sunriseone from './Pages/Sunriseone';
import Academy from './Pages/Academy';
import Youtube from './Pages/Youtube';
import Policy from './Pages/Policy';
import WC from './Pages/WC';
import Ads from './Pages/Advertising';
import News from './Pages/News';

import NaviBar from './Components/NaviBar';
import Footer from './Components/Footer';


import { Routes, Route,} from 'react-router-dom';
import Supporiting from './Components/Supporting';
import Information from './Components/Information';
import Contact from './Components/Contact';


function App() {
  return (
    <div className='App text-center font-sans'>
      <NaviBar />
      <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/football" element={<Football />}></Route>
      <Route path="/footgolf" element={<Footgolf />}></Route>
      <Route path="/sunriseone" element={<Sunriseone />}></Route>
      <Route path="/academy" element={<Academy />}></Route>
      <Route path="/youtube" element={<Youtube />}></Route>
      <Route path="/worldcup" element={<WC />}></Route>
      <Route path="/policy" element={<Policy />}></Route>
      <Route path="/advertising" element={<Ads />}></Route>
      <Route path="/news" element={<News />}></Route>



      </Routes>
      <Information />
      <Supporiting />
      <Contact />
      <Footer />


    </div>
  );
}

export default App;