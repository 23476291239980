import '../tailwind.css';
import Button from '../Components/Parts/Button';

import "swiper/css/bundle";
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y';
import WCinfo from '../Components/Parts/WCinfo';




function Home () {
  const btn =[
    {
        title:"FOOTBALL",
        link:"/football"
    },
    {
        title:"FOOTGOLF",
        link:"/footgolf"
    },
    {
        title:"SUNRISE ONE",
        link:"/sunriseone"
    },
    {
        title:"YOUTUBE",
        link:"/youtube"
    },
    {
        title:"WORLD CUP SPECIAL PAGE",
        link:"/worldcup"
    },
    ]
return (

      <div className=''>

        <main className='bg-White' id="Home">

          <div id="text-area" className='md:w-2/3 md:m-auto w-full leading-loose'>
          <h1 className='title'>加藤順大 <br className='md:hidden block' /> Official Website</h1>




          </div>

          <div id='button-area' className='md:w-2/3 md:m-auto w-full m-auto py-10'>
          {
                btn&&btn.map ((btn) => (
                    <Button
                    title={btn.title}
                    link={btn.link}
                    
                    />

                )) 
            }


          </div>





  
        </main>

        <WCinfo />

        </div>

        
        
  
        
  
        
    );
  }

export default Home;