import NaviLinks from "./NaviLinks";
function Navigation() {
    return (
        
            <nav className="hidden md:flex w-full mx-auto">

                <div id="nav"className="my-auto w-full mx-auto bg-white border-b border-solid border-b-black py-5">
                <NaviLinks />
                </div>
            </nav>
       
    );
}

export default Navigation;