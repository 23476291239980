function Policy () {
    return (
        <main className=" py-20 bg-gray-100 text-left ">
            <h1 className="text-xl font-bold text-center">特定商取引法に基づく表示/販売規約</h1>

            <div className="py-5 w-4/5 m-auto md:w-1/2">
            <p>最終更新日:2023年4月7日</p>
            <p>本プラットフォームからオンラインで本製品を注文する前によくお読みください。</p>

            <div className="py-5 leading-loose">
            <p>ブランド名:SUNRISE ONE</p>
            <p>販売者名:加藤順大</p>
            <p>代表者名:加藤順大</p>
            <p>住所:京都府京都市中京区西ノ京小堀池町8-1</p>
            <p>問い合わせ先:nobuhirokato1@gmail.com</p>
            <p>販売価格:商品に記載</p>
            <p>販売価格以外でお客様に発生する金銭:送料は発送元が負担いたします。</p>
            <p>支払方法:各種クレジットカード.銀行振込</p>
            <p>返品について:不良品以外の商品の交換はお受け致しかねます。</p>


            </div>


            </div>
        </main>
        )
}

export default Policy