import { Link } from "react-router-dom";
import {AiOutlineInstagram, AiOutlineTwitter, AiOutlineYoutube} from "react-icons/ai";
import {SiTiktok} from "react-icons/si";


function NaviLinks (props) {
    return (
        <ul className="md:w-2/3 mx-auto py-10 pl-3 md:flex md:py-0  my-auto md:justify-between leading-loose">
          <li onClick={() => props.isMobile && props.closeMobileMenu()} className="md:hover:text-ashellRed font-bold"><Link to="/">Home</Link></li>
          <li onClick={() => props.isMobile && props.closeMobileMenu()} className="md:hover:text-ashellRed font-bold"><Link to="/football">Football</Link></li>
          <li onClick={() => props.isMobile && props.closeMobileMenu()} className="md:hover:text-ashellRed font-bold"><Link to="/footgolf">Footgolf</Link></li>
          <li onClick={() => props.isMobile && props.closeMobileMenu()} className="text-orange-400 font-bold"><Link to="/worldcup">WORLD CUP</Link></li>
          <li onClick={() => props.isMobile && props.closeMobileMenu()} className="md:hover:text-ashellRed font-bold"><Link to="/sunriseone">SUNRISEONE</Link></li>
          <li onClick={() => props.isMobile && props.closeMobileMenu()} className="md:hover:text-ashellRed font-bold py-2 md:py-0"><a href="/youtube"><AiOutlineYoutube className="m-auto text-red-500 text-4xl" /></a></li>
          <li onClick={() => props.isMobile && props.closeMobileMenu()} className="md:hover:text-ashellRed font-bold py-2 md:py-0"><a href="https://instagram.com/nobuhiro1.footgolf"><AiOutlineInstagram className="m-auto text-4xl" /></a></li>
          <li onClick={() => props.isMobile && props.closeMobileMenu()} className="md:hover:text-ashellRed font-bold py-2 md:py-0"><a href="https://tiktok.com/@nobuhiro_kato"><SiTiktok className="m-auto text-4xl" /></a></li>
          <li onClick={() => props.isMobile && props.closeMobileMenu()} className="md:hover:text-ashellRed font-bold py-2 md:py-0"><a href="https://twitter.com/nobuhirokatogk1"><AiOutlineTwitter className="m-auto text-4xl" /></a></li>


        </ul>
    );
}

export default NaviLinks;