function YoutubeAdsPanel (props) {
    return (
        <div className="w-full m-auto">
            <div className="w-11/12 md:w-1/2 m-auto text-left">
                <h2 className="text-lg font-bold pb-2">提案箇所名</h2>
                <img src="../Assets/image8.jpg" alt="" />
                <ul>
                    <li>掲載箇所</li>
                    <li>価格</li>
                    <li>露出</li>
                </ul>
            </div>
            <img className="w-full m-auto" src="../Assets/ads1.jpg" alt="広告掲載提案箇所1" />
            <img className="w-full m-auto" src="../Assets/ads2.jpg" alt="広告掲載提案箇所1" />

        </div>
    )
}

export default YoutubeAdsPanel