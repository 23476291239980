import '../tailwind.css';
import YoutubePanel from "../Components/Parts/YoutubePanel.js";
import OfficialChannelPanel from "../Components/Parts/OfficialChannel.js";
import YoutubeAds from '../Components/Parts/YoutubeAds';
import Button from '../Components/Parts/Button';
function Youtube () {
    const btn =[
        {
            title:"詳しく見る",
            link:"/advertising"
        }
]

    const channel =[

        {
            uploadDate:"2022/07/09",
            videoInfo:'PK対決 vsテセーズ',
            link:' https://www.youtube.com/embed/JbxlnhBHKEg',
            videoTitle:'やらせなしのガチンコ対決です！'
        },

        {
            uploadDate:"2022/07/07",
            videoInfo:'中書島駅の散策と加藤順大の近況について',
            link:' https://www.youtube.com/embed/8S_El4jTe7Q',
            videoTitle:'公式チャンネル初回動画です！'
        },

    ]


    const youtube =[

        {   
            uploadDate:'2022/12/12',
            videoInfo:'加藤順大がマキヒカチャンネルに出演しました。',
            link:' https://www.youtube.com/embed/V4g-bS-QsaA',
            videoTitle:'テセーズ vs あしざるFC 前半戦'
        },

        {   
            uploadDate:'2022/12/11',
            videoInfo:'加藤順大がデカモリシチャンネルに出演しました。',
            link:'https://www.youtube.com/embed/liQHW4iK4SE',
            videoTitle:'テセーズ vs あしざるFC 前半戦'
        },


        
        {
            uploadDate:"2022/09/12",
            videoInfo:'テセーズseason2の初戦の模様です！',
            link:'https://www.youtube.com/embed/oU_1TkWNLTw',
            videoTitle:'テセーズ vs ヴィアべンテン滋賀'
        },

        {
            uploadDate:"2022/07/06",
            videoInfo:'テセーズの紅白戦に参加しました',
            link:' https://www.youtube.com/embed/rMGiim96q_8',
            videoTitle:'テセーズvs加藤順大 シュート対決'
        },

        {
            uploadDate:"2022/06/17",
            videoInfo:'テセーズとのシュート対決に出演しました',
            link:' https://www.youtube.com/embed/bwcW46WMBmM',
            videoTitle:'テセーズvs加藤順大 シュート対決'
        },


        {
            uploadDate:"2022/06/12",
            videoInfo:'テセーズの助っ人として1on1対決に出演しました',
            link:'https://www.youtube.com/embed/gyPytsNxSoU',
            videoTitle:'テセーズvs相生学院 1on1対決'
        },

       

        {
            uploadDate:"2022/06/11",
            videoInfo:'テセーズの助っ人としてフットサル対決に出演しました',
            link:'https://www.youtube.com/embed/gWecKYIO6uc',
            videoTitle:'テセーズvs相生学院 フットサル対決'
        },

        {
            uploadDate:"2022/05/14",
            videoInfo:'テセーズの助っ人としてフリーキック対決に出演しました',
            link:'https://www.youtube.com/embed/iRfX7fzkaCw',
            videoTitle:'テセーズvsあしざるFC フリーキック対決'
        },
        {   
            uploadDate:'2022/05/13',
            videoInfo:'加藤順大がテセーズの助っ人GKとして出演しました',
            link:'https://www.youtube.com/embed/QBaJJ4hz3BQ',
            videoTitle:'テセーズ vs あしざるFC 前半戦'
        },

        {
            link:"https://www.youtube.com/embed/8cTW1Qw25gA",
            videoTitle:'テセーズ vs あしざるFC 後半戦'
        }

       


    ]

    return (
        <div className='bg-gray-200'>
            <h1 className='py-20 text-3xl'>Youtube</h1>
            <h2 className='text-xl font-bold'>加藤順大のどんなもんじゃい💪</h2>

            <div className='w-11/12 md:w-1/2 m-auto text-left leading-loose py-5'>
            <p>プロフットゴルファー加藤順大の公式YouTubeチャンネル「加藤順大のどんなもんじゃい💪」です！フットゴルフの活動はもちろん、ゴールキーパーとしてのプレー動画やフットサルの助っ人、旅番組や居酒屋トークを展開していきます！</p>

            <div className='w-4/5 m-auto'>
            <img src="./Assets/dj-logo.png" alt="" />

            </div>

            <h2 className="text-center text-xl font-bold py-3">Youtube広告パートナー様募集のお知らせ</h2>
            <p className="text-left w-11/12 m-auto leading-loose ">いつもYouTubeチャンネル「加藤順大のどんなもんじゃい」をご覧頂きありがとうございます。元Jリーガーで現在はプロフットゴルファーの加藤順大が、アメリカのオーランドで開催されるフットゴルフワールドカップの日本代表メンバーに選出されました。それを受け、当YouTubeチャンネルで大会への密着企画を計画しております。しかしながら、最近の為替の状況などにより、航空券や宿泊費が高騰しており、全てを自己負担することが困難な状況です。このため、今回は勝手ながら、YouTubeチャンネルへのスポンサー提供をご検討いただける企業様、個人様を募集させていただきます。ご協賛を検討いただける企業様、または個人様がいらっしゃいましたら、ご連絡いただけますと幸いです。</p>

            {
                btn&&btn.map ((btn) => (
                    <Button
                    title={btn.title}
                    link={btn.link}
                    
                    />

                )) 
            }

            </div>


            <h2 className='text-xl font-bold py-5'>Youtube 出演情報</h2>

            <div>
                {youtube && youtube.map ((content)=> (
                 <YoutubePanel 
               uploadDate={content.uploadDate}
               videoInfo={content.videoInfo}
               link={content.link}
               videoTitle={content.videoTitle}
                />

                ))}

            </div>




            <div>
                {channel && channel.map ((content)=> (
                 <OfficialChannelPanel 
               uploadDate={content.uploadDate}
               videoInfo={content.videoInfo}
               link={content.link}
               videoTitle={content.videoTitle}
                />

                ))}

            </div>




            



        </div>
    )
}

export default Youtube;