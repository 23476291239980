import "../tailwind.css";
import MobileNavigation from "./MobileNavigation";
import Navigation from "./Navigation";

function NaviBar () {
    return (
        <header className="fixed block w-full z-10">
            <Navigation />
            <MobileNavigation />
        </header>

    );
}

export default NaviBar;
