import '../tailwind.css';


function Academy () {
    return (
        <main className='bg-amber-50'>
          <h1 className="title">Academy</h1>

          <p className='py-10'>加藤順大監修のサッカースクール<br />SUNRISEONE FOOTBALL ACADEMY</p>

          <h2 className='text-xl'>6月の開催スケジュール</h2>

          <div className='w-1/3 md:w-1/4 m-auto text-left my-5'>
          <p className='font-bold'>6月7日</p>
          <p>17:00~19:00</p>
          </div>

          <div className='w-1/3 md:w-1/4 m-auto text-left my-5'>
          <p className='font-bold'>6月14日</p>
          <p>17:00~19:00</p>
          </div>

          <div className='w-1/3 md:w-1/4 m-auto text-left my-5'>
          <p className='font-bold'>6月21日</p>
          <p>17:00~19:00</p>

          </div>

          <div className='w-1/3 md:w-1/4 m-auto text-left my-5'>
          <p className='font-bold'>6月28日</p>
          <p>17:00~19:00</p>
          </div>


          <ul className='leading-loose'>
            <li>開催場所:<a className='underline' href="https://goo.gl/maps/PHW2BAmA4q8qsNgN7">ケルビトフットサルクラブ</a></li>
            <li>費用:月額7000円</li>
          </ul>

          <button className='button'> <a className='block mailtoui' href="mailto:nobuhirokato1@gmail.com" >参加に関するお問い合わせはこちら</a></button>
  
        </main>
        
  
        
  
        
    );
  }

export default Academy;