import '../tailwind.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import InfoPanel from "./Parts/InfoPanel";
import {Scrollbars}  from 'react-custom-scrollbars';

function Information () {

  const news = [
    {
      date: '2023/05/25',
      news: 'フットゴルフW杯出場辞退のお知らせ',
      link: '/news',
      linkText:'詳細はこちらから'
    },

    {
      date: '2023/04/02',
      news: 'フットゴルフ日本代表に選出されました',
      link: '/worldcup',
      linkText:'詳細はこちらから'
    },

    {
      date: '2022/11/09',
      news: '雑誌Fine掲載のお知らせ',
      link: 'https://hinode-publishing.jp/?newmag=fine-2022%E5%B9%B412%E6%9C%88%E5%8F%B7',
      linkText:'詳細はこちらから'
    },

    {
      date: '2022/09/12',
      news: 'Youtube出演情報',
      link: 'https://nobuhiro-kato.com/youtube',
      linkText:'詳細はこちらから'
    },

    {
      date: '2022/09/08',
      news: 'テセーズ加入のお知らせ',
      link: 'https://www.youtube.com/watch?v=MjYV4MZ27RU',
      linkText:'詳細はこちらから'
    },

    {
      date: '2022/06/17',
      news: 'Youtube出演情報',
      link: 'https://nobuhiro-kato.com/youtube',
      linkText:'詳細はこちらから'
    },
    
    {
      date: '2022/06/12',
      news: 'Youtube出演情報',
      link: 'https://nobuhiro-kato.com/youtube',
      linkText:'詳細はこちらから'
    },

    {
      date: '2022/06/07',
      news: 'サッカースクールの6月予定を更新しました',
      link: 'https://nobuhiro-kato.com/academy',
      linkText:'詳細はこちらから'
    },

    {
      date: '2022/05/24',
      news: 'CODA LUNGA 様とのスーツサプライヤー契約締結のお知らせ',
      link: 'https://www.instagram.com/p/Cd8fgWbvEQn/?utm_source=ig_web_copy_link',
      linkText:'詳細はこちらから'
    },

    {
      date: '2022/05/24',
      news: 'TikTokアカウント開設のお知らせ',
      link: 'https://tiktok.com/@nobuhiro_kato',
      linkText:'詳細はこちらから'
    },

    {
      date: '2022/05/13',
      news: 'Youtube出演情報',
      link: 'https://nobuhiro-kato.com/youtube',
      linkText:'詳細はこちらから'
    },
    

    {
      date: '2022/05/01',
      news: 'サッカースクールの5月予定を更新しました',
      link: 'https://nobuhiro-kato.com/academy',
      linkText:'詳細はこちらから'
    },

    {
      date: '2022/04/21',
      news: '京都新聞に取材を掲載していただきました',
      link:'https://www.kyoto-np.co.jp/articles/-/776765',
      linkText:'詳細はこちらから'
    },
    {
      date: '2022/04/04',
      news: '加藤順大公式サイトを公開しました'
    }



  
  ]
    return (

        <div className='bg-gray-200 pb-5'>
          <h1 className='title'>Information</h1>

          
          <Scrollbars style={{height:300,}}>

          <div className=''>
            {news && news.map ((content)=> (
            <InfoPanel 
                 date={content.date}
                 news={content.news}
                 link={content.link}
                 linkText={content.linkText}
            
            
            />

            ))}
          </div>
          </Scrollbars>



        </div>
        

        
  
        
    );
  }

export default Information;