function WC () {
    return (
        <main className="py-20 ">
            <h1 className="font-bold text-xl py-5">FOOTGOLF WORLDCUP OLRANDO 2023 <br /> 日本代表選に選出されました。</h1>

            <div className="w-4/5 md:w-1/2 m-auto">
                <img src="./Assets/wc.jpg" alt="" />
                <p className="text-left py-5 leading-loose">この度アメリカフロリダ州オーランドで開催されます、フットゴルフワールドカップ日本代表メンバーに選出されましたのでお知らせします。</p>
            </div>

            <div className="w-4/5 md:w-1/2 m-auto text-left py-10 leading-relaxed">
            <h2 className="font-bold text-lg py-2">日本代表選出コメント</h2>
            <p>サッカー選手を引退後にフットゴルフ日本代表になることを目指し、競技へ本格参戦しました。その結果、夢だったワールドカップ出場を果たすことができました。Ｊリーガーとして浦和レッズ、大宮アルディージャ、京都サンガF.C.での経験があったおかげで、フットゴルフの世界に飛び込むことができたと思います。まだまだ旅の途中ですが、世界を驚かせ、みんなを驚かせるために、日々努力を続けています。皆さんからの応援に支えられながら、これからも全力を尽くしていきます。どうぞよろしくお願いします。</p>
            </div>

            <div className="w-4/5 md:w-1/2 m-auto text-left py-10 leading-loose">
            <h2 className="font-bold text-lg py-2">大会日程</h2>
            <ul>
                <li>5/29 個人戦1</li>
                <li>5/30 団体戦1</li>
                <li>5/31 団体戦2</li>

                <li>6/01 個人戦2</li>
                <li>6/02 団体戦ベスト8</li>
                <li>6/03 個人戦3</li>
                <li>6/04 団体戦隼決勝</li>
                <li>6/05 団体戦決勝</li>
                <li>6/06 個人戦4</li>






                <li></li>
            </ul>
            </div>

        </main>
    )
}

export default WC