import { Link } from "react-router-dom";



function WCinfo () {
    return (
        <div>
         <h2 className='py-5 text-xl font-bold'>W杯日本代表選出のお知らせ</h2>

        <div className='w-3/5 md:w-1/3 m-auto py-10'>
        <Link to="/worldcup"> <img src="./Assets/wc.jpg" alt="" /> </Link>
        <p className="text-xs py-5">詳細は画像をクリックよりご覧いただけます。 </p>

        </div>


        </div>
    )
}

export default WCinfo 