import '../tailwind.css';
import FanclubPanel from "../Components/Parts/FanclubPanel.js";
import FanclubPricePanel from "../Components/Parts/FanclubPricePanel.js";
import { Link } from "react-router-dom";



function Sunriseone () {

  const fanclub =[
    {
      title:'講演会の開催',
      text:'加藤順大が支援者さまの開催する講演会などに登壇します',
      img:'lecture.jpg',

    },
    {
      title:'限定LINEでの活動報告',
      text:'クラブメンバー限定のLINE公式アカウントで活動報告をさせていただきます',
      img:'edamame.jpg',

    },
    {
      title:'交流会参加',
      text:'加藤順大が皆様との交流会に参加します！',
      img:'izakaya-nobu.jpg',

    }

  ]

  const fanclubPrice =[
    {
      title:'10万円コース',
      text:'年3回のイベント出演、活動報告、ビジネス交流会参加',
      img:'sanga-final-game.jpg',
    },
    {
      title:'5万円コース',
      text:'年1回のイベント出演、活動報告、ビジネス交流会参加',
      img:'academy4.jpg',

    },
    {
      title:'3万円コース',
      text:'活動報告、ビジネス交流会参加',
      img:'image20.jpg',

    }

  ]
    return (
        <main className='bg-amber-50'>
          <h1 className='title'>SUNRISEONE</h1>

          <h2 className='py-5 w-11/12 m-auto font-bold'>オフィシャルブランド</h2>
          <h3 className='font-bold'>SUNRISE ONE AMIGO</h3>
          <img className='w-11/12 md:w-1/2 m-auto' src="https://nobuhiro-kato.com/image/sunriseone.jpg" alt="sunriseone" />
          <button className='w-11/12 md:w-1/2 my-4 border-2 bordder-solid border-black py-2 block mx-auto'><a className='block' href="https://nobuhirokato.stores.jp/">ショップを覗いてみる</a></button>

          <button className='w-11/12 md:w-1/2 my-4 border-2 bordder-solid border-black py-2 block mx-auto'><a class="block" href="https://instagram.com/sunriseonegloves">instagramアカウントはこちら</a></button>




          <span className='inline-block'></span>
        </main>
        
  
        
  
        
    );
  }

export default Sunriseone;