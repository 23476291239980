import '../tailwind.css';


import { Pagination, Autoplay,Scrollbar,A11y} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css/bundle";
import 'swiper/css/autoplay';
import 'swiper/css/a11y';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y';








function Football () {
    return (
        <main className='bg-amber-50'>
          <h1 className='title'>Footballer</h1>

      <div id="football" class="py-10 w-11/12 m-auto">
        <Swiper
        className="mySwiper"
        modules={[Pagination, Autoplay, Scrollbar,A11y]}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{
          dynamicBullets: true,
        }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
        autoplay={true}
        autoplayTimeout={5}
        speed={500}
        loop={true}
        
        >

          <SwiperSlide><img className='imageSize' src="https://nobuhiro-kato.com/image/urawa1.jpg" alt="Urawa" />  </SwiperSlide>


        </Swiper>

        <div id="urawa" class="my-10 text-center">

          <h3 class="text-xl font-bold py-3">浦和レッズ</h3>
          <p >2003~2014</p>


        </div>
  
        <div id="omiya" class="my-10 text-center">
        <Swiper
        className="mySwiper"
        modules={[Pagination, Autoplay, Scrollbar,A11y]}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{
          dynamicBullets: true,
        }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
        autoplay={true}
        autoplayTimeout={5}
        speed={500}
        loop={true}
        
        >

          <SwiperSlide><img className='imageSize' src="https://nobuhiro-kato.com/image/omiya1.jpg" alt="Sanga" />  </SwiperSlide>
          <SwiperSlide><img className='imageSize' src="https://nobuhiro-kato.com/image/omiya2.jpg" alt="Sanga" />  </SwiperSlide>


        </Swiper>

          <h3 class="text-xl font-bold py-3">大宮アルディージャ</h3>
          <p >2015~2018</p>
        </div>
  
        <div id="kyoto" class="my-10 text-center">
          
        <Swiper
        className="mySwiper"
        modules={[Pagination, Autoplay, Scrollbar,A11y]}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{
          dynamicBullets: true,
        }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
        autoplay={true}
        autoplayTimeout={5}
        speed={500}
        loop={true}
        
        >

          <SwiperSlide><img className='imageSize' src="https://nobuhiro-kato.com/image/kyoto1.jpg" alt="Sanga" />  </SwiperSlide>
          <SwiperSlide><img className='imageSize' src="https://nobuhiro-kato.com/image/kyoto2.jpg" alt="Sanga" />  </SwiperSlide>
          <SwiperSlide><img className='imageSize' src="https://nobuhiro-kato.com/image/kyoto3.jpg" alt="Sanga" />  </SwiperSlide>
          <SwiperSlide><img className='imageSize' src="https://nobuhiro-kato.com/image/kyoto4.jpg" alt="Sanga" />  </SwiperSlide>


        </Swiper>

          <h3 class="text-xl font-bold py-3">京都サンガF.C.</h3>
          <p>2019~2020</p>
        </div>
  
      </div>



  
        </main>
        
  
        
  
        
    );
  }

export default Football;