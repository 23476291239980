function YoutubePanel (props) {
    return (
    <div className='py-5 w-11/12 md:w-1/2 m-auto'>
        <small className="block text-left">{props.uploadDate}</small>
        <h3 className="text-left py-3 font-bold">{props.videoInfo}</h3>
        <iframe className="w-full h-64 md:h-96" src={props.link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <p className='text-left'>{props.videoTitle}</p>
    </div>
)
}
export default YoutubePanel;