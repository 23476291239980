import YoutubeAdsPanel from "../Components/Parts/YoutubeAdsPanel"

function Ads () {
    return (
        <main>
            <h1 className="py-20 text-xl font-bold">Youtubeスポンサー様募集について</h1>
            <YoutubeAdsPanel />
        </main>
    )
}

export default Ads