import '../tailwind.css';
import NaviLinks from './NaviLinks';


function Footer() {
  return (
    <footer className='bg-black w-full text-white'>
      <div id="footer-wrapper" className='w-full mx-auto md:flex my-auto'>

      <div id="logo-name-wrapper" className='md:w-full'>

      <div className='block text-offWhite text-center font-biz font-bold'>
        <p className=''>加藤順大公式サイト</p>
      </div>

      <NaviLinks />

      </div>

      </div>

      <div className='text-xs leading-loose w-11/12 m-auto py-5'>
        <p>このwebサイトは <a className='underline' href="https://stage-kyoto.com">株式会社ステージ</a>と<a className='underline' href="https://cachitto-page.com">カチッとページ</a>のサポートで運営しています。</p>
        <p>This website is supported by CO LTD STAGE and Cachitto Page</p>
      </div>
      


    </footer>
  );
}

export default Footer;
