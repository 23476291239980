import ReactDOM from 'react-dom';
import App from './App';
import './tailwind.css';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './Components/ScrollToTop';

ReactDOM.render(
  <BrowserRouter>
  <ScrollToTop />
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);