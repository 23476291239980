import '../tailwind.css';

import { Pagination, Autoplay,Scrollbar,A11y} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css/bundle";
import 'swiper/css/autoplay';
import 'swiper/css/a11y';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y';
import WCinfo from '../Components/Parts/WCinfo';



function Footgolf () {
    return (
        <main className='bg-amber-50'>
          <div className='w-11/12 m-auto'>
          <h1 className='title'>Footgolfer</h1>

          <p>2021年よりプロフットゴルファーとして活動中</p>

          <Swiper
        className="mySwiper"
        modules={[Pagination, Autoplay, Scrollbar,A11y]}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{
          dynamicBullets: true,
        }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
        autoplay={true}
        autoplayTimeout={5}
        speed={500}
        loop={true}
        
        >

          <SwiperSlide><img className='imageSize' src="https://nobuhiro-kato.com/image/image8.jpg" alt="Footgolf" />  </SwiperSlide>
          <SwiperSlide><img className='imageSize' src="https://nobuhiro-kato.com/image/image10.jpg" alt="Footgolf" />  </SwiperSlide>
          <SwiperSlide><img className='imageSize' src="https://nobuhiro-kato.com/image/image11.jpg" alt="Footgolf" />  </SwiperSlide>
          <SwiperSlide><img className='imageSize' src="https://nobuhiro-kato.com/image/image12.jpg" alt="Footgolf" />  </SwiperSlide>


        </Swiper>


          </div>

          <WCinfo />

  
        </main>
        
  
        
  
        
    );
  }

export default Footgolf;